define(['workbox', 'siteObj'], (workbox, siteObj) => {

  if (siteObj.config && siteObj.config.hasServiceWorker === 'true') {

    if ('serviceWorker' in navigator) {

      const wb = new workbox.Workbox('/sw.js');

      wb.addEventListener('installed', event => {
        if (event.isUpdate) {
          window.location.reload();
        }
      });

      wb.register();
    }
  }
});
